@mixin optionalAtRoot($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optionalAtRoot('::-webkit-input-placeholder') {
    @content;
  }

  @include optionalAtRoot(':-moz-placeholder') {
    @content;
  }

  @include optionalAtRoot('::-moz-placeholder') {
    @content;
  }

  @include optionalAtRoot(':-ms-input-placeholder') {
    @content;
  }
}

@mixin container {
  .container {
    max-width: 1440px;
    margin: 0 auto;
  }

  @media (width <= calc($breakpoint-md - 1px)) {
    .container {
      padding: 0 16px;
    }
  }

  @media (min-width: $breakpoint-md) {
    .container {
      padding: 0 32px;
    }
  }

  @media (min-width: $breakpoint-xl) {
    .container {
      padding: 0 64px;
    }
  }
}
