$color-bg: #06000f;
$color-pink: #c000b1;
$color-purple: #584179;
$color-lavender: #ab8add;

/* New color palette */
$color-primary-dark: #24075a;
$color-primary-default: #4e1d9e;
$color-primary-light: #7e23f5;
$color-secondary-dark: #4b014f;
$color-secondary-default: #c21db3;
$color-secondary-light: #f71ef5;
$color-accent-dark: #003942;
$color-accent-default: #0bd9a8;
$color-accent-light: #00ffbc;
$color-warning-dark: #5d2721;
$color-warning-default: #d26020;
$color-warning-light: #eea56f;
$color-error-dark: #720d2f;
$color-error-default: #da2f44;
$color-error-light: #f290ab;
$color-success-dark: #18342b;
$color-success-default: #1c9a71;
$color-success-light: #8bd2a4;
$color-neutral-900: #1a0e22;
$color-neutral-850: #2b1839;
$color-neutral-800: #361f46;
$color-neutral-700: #402a4f;
$color-neutral-650: #4a3659;
$color-neutral-600: #5e4c6b;
$color-neutral-500: #7c6d87;
$color-neutral-400: #9b8fa3;
$color-neutral-300: #afa5b5;
$color-neutral-250: #c2bbc7;
$color-neutral-200: #d7d2da;
$color-neutral-100: #ebe8ec;
$color-neutral-50: #fcf8f9;

/* Screen size breakpoints */
$breakpoint-sm: 0;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
