@use "sass:color"; @import "src/styles/variables.module"; @import "src/styles/mixins.module";
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

html {
  -webkit-font-smoothing: antialiased;
  text-size-adjust: none;
}

body {
  color: $color-neutral-50;
  font-weight: 400;
  font-size: 16px;
  background:
    url('/landing/assets/bg.png') 0% 0% / contain no-repeat,
    url('/landing/assets/bg.png') 0% 0% / contain no-repeat;
  background-color: $color-bg;

  @media (min-width: $breakpoint-md) {
    background: $color-bg url('/landing/assets/bg.png') 0 0 / contain no-repeat;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

p {
  margin: 0;
}

h1 {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;

  @media (min-width: $breakpoint-md) {
    font-size: 52px;
    line-height: 60px;
  }
}

h2 {
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
}

button,
input {
  font-size: inherit;
  font-family: inherit;
}

input:focus {
  outline: none;
}

button:hover {
  cursor: pointer;
}

b,
strong {
  font-weight: 700;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: decimal inside none;
}

label {
  color: $color-neutral-50;
  font-size: 16px;
}

@mixin inputStyle {
  @include placeholder {
    color: rgba($color-neutral-50, 0.4);
    font-weight: 400;
    font-size: 16px;
    font-family: inherit;
  }

  height: 40px;
  padding: 0 16px;
  color: $color-neutral-50;
  font-weight: 400;
  font-size: 16px;
  font-family: inherit;
  background: transparent;
  border: 1px solid rgba($color-neutral-50, 0.4);
  border-radius: 6px;
  transition: border 150ms ease-in-out;
  appearance: none;

  &:focus {
    border-color: $color-pink;
    outline: none;
  }
}

input[type='text'],
input[type='email'],
input[type='number'],
input[type='password'],
textarea {
  @include inputStyle;
}

textarea {
  @include inputStyle;

  height: 200px;
  padding: 8px 16px;
  resize: none;
}

input[type='checkbox'] {
  width: 16px;
  height: 16px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 10px 8.16px;
  border: 1px solid $color-neutral-50;
  border-radius: 3px;
  transition:
    background 150ms ease-in-out,
    border 150ms ease-in-out;
  appearance: none;

  &:checked {
    background-color: $color-pink;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='9' fill='none'%3E%3Cpath fill='%23fff' d='m2.705 7.988-2.43-2.56a1.03 1.03 0 0 1 0-1.403l.334-.35a.908.908 0 0 1 1.332 0l1.53 1.612L8.06.453a.908.908 0 0 1 1.332 0l.333.351c.229.24.315.574.26.885-.034.19-.356.477-.495.624L4.237 7.846c-.211.223-.488.477-.766.477-.277 0-.554-.112-.766-.335Z'/%3E%3C/svg%3E");
    border-color: $color-pink;
  }
}
